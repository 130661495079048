<template>
  <b-row>
    <b-col>
      <b-card no-body>
        <b-card-body class="p-0">
          <b-row class="mb-1">
            <b-col>
              <h2 class="text-center m-2">
                Catatan Transaksi
              </h2>
            </b-col>
          </b-row>
          <b-table
            id="invoice-list"
            :key="tableData.page"
            :fields="tableFields"
            striped
            responsive="lg"
            small
            :items="tableData.data"
            :busy="isBusy"
          >

            <!-- numbering template -->
            <template #cell(index)="data">
              {{ (tableData.page === 1 ? data.index + 1 : data.index + (tableData.page * tableData.perPage) + 1 - 10) }}
            </template>

            <!-- get paymnet_detail link -->
            <template #cell(orderId)="data">
              <b-link
                :to="{ name: 'subcriptionHistoryDetail', query: { id: data.item.orderId } }"
              >
                {{ data.item.orderId }}
              </b-link>
            </template>

            <!-- subscribe status template -->
            <template
              #cell(paymentStatus)="data"
            >
              <b-badge
                v-if="data.item.paymentStatus.toLowerCase() === 'settlement'"
                pill
                variant="success"
              >
                Disetujui
              </b-badge>
              <b-badge
                v-if="data.item.paymentStatus.toLowerCase() === 'pending'"
                pill
                variant="warning"
              >
                Diproses
              </b-badge>
              <b-badge
                v-if="data.item.paymentStatus.toLowerCase() === 'expire'"
                pill
                variant="danger"
              >
                Ditolak
              </b-badge>
            </template>

            <!-- <template
              #cell(created_at)="data"
            >
              {{ moment(data.item.created_at).format('DD MMMM YYYY') }}
            </template> -->

            <!-- no data template -->
            <template
              v-if="tableData.length === 0"
              #top-row
            >
              <td
                colspan="12"
                class="text-center"
              > Belum ada history invoice </td>
            </template>

            <!-- busy stated -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong> Memuat Data...</strong>
              </div>
            </template>
          </b-table>
        </b-card-body>

        <b-pagination
          v-model="tableData.page"
          :total-rows="tableData.totalRows"
          :per-page="tableData.perPage"
          align="right"
          size="md"
          class="mt-2 mr-2"
          aria-controls="products-master"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  defineComponent, reactive, ref, computed, watch, onMounted,
} from '@vue/composition-api'
import {
  BCardBody,
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BSpinner,
  BMedia,
  BLink,
  BImg,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BModal,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import moment from 'moment'

export default defineComponent({
  name: 'Productshopee',
  components: {
    BCard,
    BCardBody,
    BRow,
    BPagination,
    BCol,
    BButton,
    BTable,
    BSpinner,
    BMedia,
    BLink,
    BImg,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BModal,
  },
  setup() {
    const isBusy = ref(false)
    const tableFields = reactive([
      {
        key: 'index',
        label: 'No',
        class: 'text-center',
      },
      {
        key: 'orderId',
        label: 'Kode Invoice',
        class: 'w-30',
      },
      {
        key: 'package',
        label: 'Paket Pembayaran',
      },
      {
        key: 'months',
        label: 'Jumlah Bulan',
        class: 'text-center',
      },
      {
        key: 'paymentDate',
        label: 'Tanggal Pembelian',
        class: 'text-center',
      },
      {
        key: 'totalPayment',
        label: 'Jumlah tagihan',
        class: 'text-center',
      },
      {
        key: 'paymentStatus',
        label: 'Status',
        class: 'text-center',
      },
      {
        key: 'action',
        label: '',
        class: 'text-center',
      },
    ])

    const tableData = reactive({
      page: 1,
      perPage: 10,
      totalRows: computed(() => store.state.subscribe.allPayment.total),
      data: computed(() => {
        isBusy.value = false
        store.state.subscribe.allPayment.data.forEach(payment => {
          if (payment.paymentData) {
            payment.paymentDate = payment.paymentData.transaction_time
            payment.totalPayment = `${payment.paymentData.currency} ${payment.paymentData.gross_amount}`
            payment.paymentStatus = payment.paymentData.transaction_status
          }
          if (!payment.paymentData) {
            payment.paymentDate = '-'
            payment.totalPayment = '-'
            payment.paymentStatus = '-'
          }
          // console.log(payment, '>> payment')
          // console.log(payment.paymentData, '>> paymentData')
        })
        return store.state.subscribe.allPayment.data

        // return store.state.subscribe.allPayment.data
      }),
    })

    const getData = async () => {
      // fecth data from store
      isBusy.value = true
      const payload = {
        page: tableData.page,
      }
      store.dispatch('subscribe/getAllPayment', payload)
    }

    onMounted(() => {
      getData()
    })

    watch(() => tableData.page, () => {
      getData()
    })

    return {
      tableFields,
      isBusy,
      tableData,
      moment,
    }
  },
})
</script>
